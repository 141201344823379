import { createApp } from 'vue';
import App from './App.vue';

import axios from 'axios';

// Vuetify
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import router from './router';

axios.defaults.baseURL =
  process.env.NODE_ENV === 'production' ? '/api/' : 'http://localhost:3000/api';

const app = createApp(App);

// Make Axios available globally
app.config.globalProperties.$axios = axios;

const vuetify = createVuetify({
  components,
  directives,
});

app.use(vuetify).use(router).mount('#app');
