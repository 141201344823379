<template>
  <v-container class="login-container">
    <h1>Login</h1>
    <form @submit.prevent="login">
      <div>
        <label for="username">Email:</label>
        <input type="text" v-model="form.email" required />
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="password" v-model="form.password" required />
      </div>
      <button type="submit">Login</button>
    </form>
    <p v-if="error">{{ error }}</p>
  </v-container>
</template>

<script>
import api from '../../api-service';
import authService from './auth.service';

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      error: '',
    };
  },
  methods: {
    async login() {
      try {
        authService.removeToken();
        const response = await api.post('/auth/login', {
          ...this.form,
        });
        const token = response.data.access_token;
        authService.saveToken(token);

        this.$router.push('/admin/dashboard'); // Redirect to a secure route
      } catch (err) {
        this.error = 'Invalid username or password';
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 300px;
  margin: 0 auto;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}
label {
  display: block;
  margin-bottom: 0.5em;
}
input {
  width: 100%;
  padding: 0.5em;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 3px;
}
button {
  width: 100%;
  padding: 0.7em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
p {
  color: red;
}
</style>
