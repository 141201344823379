import api from '@/api-service';

const BASE_URL = '/storage-location';

export default {
  getAll() {
    return api.get(BASE_URL);
  },
  get(id) {
    return api.get(`${BASE_URL}/${id}`);
  },
  create(data) {
    return api.post(BASE_URL, data);
  },
  update(id, data) {
    return api.put(`${BASE_URL}/${id}`, data);
  },
  delete(id) {
    return api.delete(`${BASE_URL}/${id}`);
  },
};
