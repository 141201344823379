<template>
  <div>
    <h1>Категории</h1>
    <ul>
      <li v-for="category in categories" :key="category.id">
        {{ category.name }}
        <ul>
          <li
            v-for="subcategory in category.subcategories"
            :key="subcategory.id"
          >
            {{ subcategory.name }}
            <button @click="editCategory(category.id)">Edit</button>
            <button @click="deleteCategory(category.id)">Delete</button>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import api from '@/api-service';
export default {
  name: 'CategoriesPage',
  data() {
    return {
      categories: [],
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      try {
        const response = await api.get('/categories');
        this.categories = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    editCategory(categoryId) {
      // Handle edit category logic here
      console.log(categoryId);
    },
    deleteCategory(categoryId) {
      // Handle delete category logic here
      console.log(categoryId);
    },
  },
};
</script>
