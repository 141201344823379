<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" @click="showForm = !showForm"
          >Добавить розничную полку</v-btn
        >
        <v-dialog v-model="showForm" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Location Details</span>
            </v-card-title>
            <v-card-text>
              <form @submit.prevent="submitForm">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="form.aisle"
                        label="Ряд"
                        @input="form.aisle = form.aisle.toUpperCase()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="form.shelf"
                        label="Стойка"
                        @input="form.shelf = form.shelf.toUpperCase()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model="form.bin"
                        label="Ячейка"
                        @input="form.bin = form.bin.toUpperCase()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="locationCode"
                        label="Код места хранения"
                        :readonly="true"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="blue darken-1" text @click="showForm = false"
                >Close</v-btn
              >
              <v-btn color="blue darken-1" text @click="submitForm">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-list dense>
          <template v-for="(item, index) in items" :key="index">
            <v-list-item>
              <v-list-item-content class="d-flex content-center align-center">
                <div>
                  <v-list-item-title class="text-left text-bold">{{
                    item.locationCode
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="text-left"
                    >Ряд: {{ item.aisle }} | Стойка: {{ item.shelf }} | Ячейка:
                    {{ item.bin }}</v-list-item-subtitle
                  >
                </div>

                <v-list-item-action class="justify-end">
                  <v-btn icon small @click="editItem(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon small color="red" @click="deleteItem(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index < items.length - 1"></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import storageLocationService from '@/services/admin/storage-location.service';

export default {
  name: 'StorageLocation',
  async mounted() {
    this.getItems();
  },
  data() {
    return {
      showForm: false,
      form: {
        aisle: '',
        shelf: '',
        bin: '',
      },
      items: [], // Это может быть массив мест хранения, если вы работаете с ними
    };
  },
  computed: {
    locationCode: {
      get() {
        return `${this.form.aisle}${this.form.shelf}${this.form.bin}`;
      },
    },
  },
  methods: {
    async getItems() {
      this.items = (await storageLocationService.getAll()).data;
    },
    toggleForm() {
      this.showForm = !this.showForm;
    },
    submitForm() {
      console.log(this.form);
      if (this.form.id) this.updateItem();
      else this.addItem();
    },
    async addItem() {
      // Предполагается, что метод должен теперь добавлять новое место хранения
      console.log('Adding storage location:', this.newItem);
      // Здесь должен быть код для добавления нового места хранения в базу данных
      await storageLocationService.create(this.form);
      // После добавления очистить форму
      this.form = { locationCode: '', aisle: '', shelf: '', bin: '' };
      // Обновить список мест хранения, если необходимо
      await this.getItems();
    },
    editItem(item) {
      // Метод для редактирования элемента/места хранения
      this.showForm = true;
      this.form = { ...item };
    },
    async updateItem() {
      await storageLocationService.update(this.form.id, this.form);
      this.form = {};
      this.showForm = false;

      this.getItems();
    },
    async deleteItem(item) {
      // Метод для удаления элемента/места хранения
      try {
        const confirmed = confirm('Уверены что хотите удалить элемент?');
        if (confirmed) {
          await storageLocationService.delete(item.id);
          this.items = this.items.filter((i) => i.id !== item.id);
        }
      } catch (error) {
        console.log(error);
        throw new Error('Error deleting item');
      }
    },
  },
};
</script>

<style scoped>
.storage-location {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}

div {
  flex-grow: 1;
}

span {
  font-weight: bold;
}

button {
  margin-left: 10px;
}
</style>
