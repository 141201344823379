<template>
  <form @submit.prevent="submitForm">
    <!-- <v-radio-group>
      <v-radio
        label="Одежда"
        name="productType"
        v-model="form.checkboxes"
        value="clothes"
      />
      <v-radio
        label="Обувь"
        name="productType"
        v-model="form.checkboxes"
        value="shoes"
      />
    </v-radio-group> -->

    <v-row>
      <v-col col="6">
        <v-text-field
          variant="underlined"
          v-model="form.characteristic"
          label="Характеристика"
        />
      </v-col>
      <v-col col="6">
        <v-text-field
          variant="underlined"
          label="Название продукта"
          v-model="form.q"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-btn type="sumbit">Search</v-btn>
  </form>
</template>

<script>
export default {
  name: 'FormFilter',
  data() {
    return { form: {}, stockItems: [], title: 'top up' };
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      this.$emit('updateForm', this.form);
    },
  },
};
</script>
