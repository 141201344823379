<template>
  <div class="dashboard">
    <v-app>
      <v-navigation-drawer
        v-model="drawer"
        app
        clipped
        fixed
        width="300"
        class="sidebar"
      >
        <v-list dense>
          <v-list-item
            class="d-flex"
            v-for="(item, index) in menuItems"
            :key="index"
            :to="item.route"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app clipped-left>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Admin Panel</v-toolbar-title>
      </v-app-bar>

      <v-main>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'DashboardPage',
  data() {
    return {
      drawer: true,
      menuItems: [
        {
          title: 'Ячейки склада',
          icon: 'mdi-archive',
          route: { name: 'admin-product-storage-location' },
        },
        {
          title: 'Продукты',
          icon: 'mdi-package-variant',
          route: { name: 'admin-products' },
        },
        {
          title: 'Загрузка картинок продуктов',
          icon: 'mdi-image',
          route: { name: 'admin-products-images' },
        },
        {
          title: 'Активности',
          icon: 'mdi-chart-line',
          route: { name: 'admin-activities' },
        },
        {
          title: 'Категории',
          icon: 'mdi-format-list-bulleted',
          route: { name: 'admin-product-categories' },
        },
        {
          title: 'Пользователи',
          icon: 'mdi-account',
          route: { name: 'admin-users' },
        },
      ],
    };
  },
};
</script>

<style>
.dashboard {
  display: flex;
  height: 100vh;
}

.sidebar {
  background-color: #2c3e50;
  padding: 20px;
  color: white;
}

.sidebar a {
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: white;
  text-decoration: none;
}

.sidebar a i {
  margin-right: 10px;
}

.sidebar a:hover {
  background-color: #34495e;
}

.content {
  padding: 20px;
}

.v-list-item__content {
  display: flex;
  align-items: center;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .dashboard {
    flex-direction: column;
  }

  .sidebar {
    padding: 10px;
  }

  .content {
    padding: 10px;
  }
}
</style>
