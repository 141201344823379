<template>
  <div>
    <h1>Users</h1>
    <div>
      <button @click="changeView('list')">List View</button>
      <button @click="changeView('grid')">Grid View</button>
    </div>
    <div v-if="currentView === 'list'">
      <ul>
        <li v-for="user in users" :key="user.id">
          {{ user.email }}
          <button @click="editUser(user.id)">Edit</button>
          <button @click="confirmDeleteUser(user.id)">Delete</button>
        </li>
      </ul>
    </div>
    <div v-else-if="currentView === 'grid'">
      <div class="grid-container">
        <div v-for="user in users" :key="user.id" class="grid-item">
          {{ user.email }}
          <button @click="editUser(user.id)">Edit</button>
          <button @click="confirmDeleteUser(user.id)">Delete</button>
        </div>
      </div>
    </div>
    <p v-else>No users to display.</p>
  </div>
</template>

<script>
import api from '@/api-service';

export default {
  name: 'UsersPage',
  data() {
    return {
      users: [],
      currentView: 'list',
    };
  },
  methods: {
    async getUsers() {
      try {
        const response = await api.get('/users');
        this.users = response.data;
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    changeView(view) {
      this.currentView = view;
    },
    editUser(userId) {
      // Implement your edit user logic here
      console.log(`Editing user with ID: ${userId}`);
    },
    confirmDeleteUser(userId) {
      if (window.confirm('Are you sure you want to delete this user?')) {
        this.deleteUser(userId);
      }
    },
    deleteUser(userId) {
      // Implement your delete user logic here
      console.log(`Deleting user with ID: ${userId}`);
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.grid-item {
  border: 1px solid #ccc;
  padding: 10px;
}
</style>
