<template>
  <v-container>
    <h3 class="text-left">Motion App</h3>

    <FormFilter class="mb-4" @updateForm="getStock" />

    <v-list lines="one">
      <v-list-item
        align="left"
        style="border-bottom: 1px solid black"
        v-for="(item, i) in Object.keys(items)"
        :key="i"
        :title="items[item].product_title"
      >
        <div class="d-flex">
          <span class="text-secondary text-body-2">{{
            items[item].characteristic
              .map((a) => {
                return `${a.characteristic}(${a.quantity})`;
              })
              .join(' | ')
          }}</span>
          <div class="ml-auto text-primary text-body-2 pl-2">
            <v-chip size="small">{{ items[item].price }}</v-chip>
          </div>
        </div>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import FormFilter from './components/FilterForm.vue';

export default {
  name: 'App',
  components: { FormFilter: FormFilter },
  async mounted() {
    console.log('get stocks ');
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    getStock(data) {
      console.log(data);
      const fl = true;
      if (!!data && fl) {
        this.$axios
          .get('/warehouse-stock', { params: { ...data } })
          .then((res) => {
            const result = {};
            res.data.forEach((e) => {
              if (!result[e.product_key]) {
                result[e.product_key] = { ...e };
                result[e.product_key].characteristic = [
                  { characteristic: e.characteristic, quantity: e.quantity },
                ];
              } else {
                result[e.product_key].characteristic.push({
                  characteristic: e.characteristic,
                  quantity: e.quantity,
                });
              }

              result[e.product_key].characteristic.sort((a, b) => {
                return a.characteristic > b.characteristic;
              });
            });

            this.items = result;
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
