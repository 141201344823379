import { createRouter, createWebHistory } from 'vue-router';
import authService from './pages/auth/auth.service';
import LoginPage from './pages/auth/login-page.vue';
import MainPage from './pages/main/main-page.vue';
import RegisterPage from './pages/auth/register-page.vue';
import DashboardPage from './pages/admin/dashboard/dashboard-page.vue';
import StorageLocationPage from './pages/admin/storage-location-page/storage-location-page.vue';
import ProductsPage from './pages/admin/products-page/products-page.vue';
import ActivitiesPage from './pages/admin/activities-page/activities-page.vue';
import CategoriesPage from './pages/admin/categories-page/categories-page.vue';
import UsersPage from './pages/admin/users-page/users-page.vue';
import ProductsImagesUploadPage from './pages/admin/products-images-upload-page/products-images-upload-page.vue';

const routes = [
  { path: '/', component: MainPage },
  { path: '/auth/login', component: LoginPage },
  { path: '/auth/register', component: RegisterPage },
  {
    path: '/admin/dashboard',
    component: DashboardPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'storage-location',
        component: StorageLocationPage,
        name: 'admin-product-storage-location',
      },
      {
        path: 'products',
        component: ProductsPage,
        name: 'admin-products',
      },
      {
        path: 'products-images',
        component: ProductsImagesUploadPage,
        name: 'admin-products-images',
      },
      {
        path: 'activities',
        component: ActivitiesPage,
        name: 'admin-activities',
      },
      {
        path: 'categories',
        component: CategoriesPage,
        name: 'admin-product-categories',
      },
      {
        path: 'users',
        component: UsersPage,
        name: 'admin-users',
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const token = authService.getToken();
    if (!token) {
      next('/auth/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
