<template>
  <div>
    <h1>Activities</h1>
    <p>Content for Activities tab.</p>
  </div>
</template>

<script>
export default {
  name: 'ActivitiesPage',
};
</script>
