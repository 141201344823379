<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="d-flex align-center">
            Товары
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              density="compact"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-data-table
              density="compact"
              :headers="headers"
              :items="products"
              class="elevation-1"
              :search="search"
              align="left"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="storageLocation(item)">
                  mdi-nas
                </v-icon>
                <v-icon small class="mr-2" @click="imagesDialog(item)">
                  mdi-image
                </v-icon>
              </template>
              <template v-slot:item.image="{ item }">
                <v-img
                  v-if="item.images[0]"
                  :src="item.images[0]?.src"
                  width="50"
                  height="50"
                ></v-img>
              </template>
              <template v-slot:no-data>
                <h1>Нет загруженных продуктов</h1>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="uploadDialog"
      max-width="600px"
      @click:outside="uploadDialog = false"
    >
      <div class="preloader" v-if="isProgress">
        <v-progress-circular
          indeterminate
          size="60"
          color="white"
        ></v-progress-circular>
      </div>
      <v-card>
        <v-card-title>
          <span class="headline">Загрузка изображений</span>
        </v-card-title>
        <v-card-text>
          <form>
            <v-file-input
              v-model="selectedImages"
              accept="image/*"
              multiple
              label="Выберите изображения"
            ></v-file-input>
          </form>

          <div>
            <v-row>
              <v-col
                v-for="image in selectedProduct.images"
                :key="image.id"
                cols="4"
                class="text-center position-relative"
              >
                <v-img
                  :src="image.src"
                  width="200"
                  height="200"
                  contain
                ></v-img>
                <v-icon
                  class="position-absolute image__delete-icon"
                  style="top: 10px; right: 10px; color: red"
                  @click="deleteImage(image)"
                >
                  mdi-delete
                </v-icon>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="uploadImages">
            Загрузить
          </v-btn>
          <v-btn color="red darken-1" text @click="uploadDialog = false">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      @click:outside="dialog = false"
    >
      <div class="preloader" v-if="isProgress">
        <v-progress-circular
          indeterminate
          size="60"
          color="white"
        ></v-progress-circular>
      </div>
      <v-card>
        <v-card-title>
          <span class="headline">{{ locationItems[0].product_title }}</span>
        </v-card-title>
        <v-card-text>
          <form autocomplete="off">
            <v-container>
              <v-row
                v-for="locationItem in locationItems"
                :key="locationItem.id"
              >
                <v-col cols="6">
                  <v-text-field
                    density="compact"
                    v-model="locationItem.characteristic"
                    label="Продукт"
                    readonly
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    v-model="locationSelectorDefaults[locationItem.id]"
                    density="compact"
                    autocomplete="off"
                    label="Код ячейки"
                    :items="storageLocations"
                    item-title="locationCode"
                    item-value="locationCode"
                    :return-object="true"
                    multiple
                    @update:model-value="
                      selectProductLocation(locationItem, $event)
                    "
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              dialog = false;
              locationSelectorDefaults = [];
            "
            >Закрыть</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from '@/api-service';

export default {
  name: 'ProductsImagesUploadPage',
  data() {
    return {
      isProgress: true,
      search: '',
      uploadDialog: false,
      selectedProduct: null,
      dialog: false,
      selectedImages: [],
      locationSelectorDefaults: [],
      products: [],
      locationForm: {},
      storageLocations: [],
      headers: [
        // { title: 'Product Group', key: 'product_group' },
        { title: 'Картинка', key: 'image', align: 'left' },
        { title: 'Код', key: 'product_key', align: 'left' },
        { title: 'Название', key: 'product_title', align: 'start' },
        { title: 'Характеристика', key: 'characteristic', align: 'start' },
        { title: 'Кол-во', key: 'quantity', align: 'start' },
        // { title: 'Total Cost Amount', key: 'total_cost_amount',align: 'start' },
        // { title: 'Total Retail Amount', key: 'total_retail_amount',align: 'start' },
        { title: 'Цена', key: 'price', align: 'start' },
        { title: 'Действия', key: 'actions', align: 'center' },
      ],
    };
  },
  async mounted() {
    this.isProgress = true;
    const response = await api.get(`/warehouse-stock`, {
      params: { storageLocations: true },
    });
    const productCodes = [];
    this.products = response.data
      .filter((el) => el.images.length < 3)
      .filter((el) => {
        if (!productCodes.includes(el.product_key)) {
          productCodes.push(el.product_key);
          return true;
        }
      }); // Предполагается, что API возвращает данные в свойстве data

    const storageLocations = await api.get(`/storage-location`);
    this.storageLocations = storageLocations.data;

    this.isProgress = false;
  },
  methods: {
    async uploadImages() {
      this.isProgress = true;
      const formData = new FormData();
      for (let i = 0; i < this.selectedImages.length; i++) {
        formData.append(
          'images',
          this.selectedImages[i],
          this.selectedImages[i].name,
        );
      }
      let response;
      try {
        response = await api.post(
          `/warehouse-stock/${this.selectedProduct.id}/upload-images`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        console.log('Response:', response);
      } catch (error) {
        console.error('Error:', error);
        this.isProgress = false;
        return;
      }

      this.products.forEach((product, index) => {
        if (product.product_key === response.data.product_key) {
          this.products[index].images = response.data.images;
        }
      });

      this.selectedImages = [];

      this.selectedProduct = response.data;
      this.isProgress = false;
    },
    imagesDialog(item) {
      this.selectedProduct = item;
      this.uploadDialog = true;
    },
    storageLocation(item) {
      this.dialog = true;
      this.isProgress = true;
      this.locationItems = this.products.filter(
        (el) => el.product_key === item.product_key,
      );

      this.locationItems.sort((a, b) => {
        return +a.characteristic - +b.characteristic;
      });

      this.locationSelectorDefaults = this.locationItems.reduce((acc, el) => {
        if (el.storageLocations.length > 0) {
          acc[el.id] = el.storageLocations.map((el1) => {
            return el1.locationCode;
          });
        }
        return acc;
      }, {});

      this.isProgress = false;
    },
    async selectProductLocation(item, e) {
      this.isProgress = true;
      const stockItem = (
        await api.put(`/warehouse-stock/${item.id}/storage-location`, {
          storageLocationsIds: e.map((el) => el.id),
        })
      ).data;

      this.updateLocalProduct(stockItem);

      this.isProgress = false;
    },

    updateLocalProduct(stockItem) {
      const index = this.products.findIndex(
        (product) => product.id === stockItem.id,
      );
      if (index !== -1) {
        this.products[index] = stockItem;
      }
    },

    async deleteImage(image) {
      const confirmed = confirm(
        'Вы уверены, что хотите удалить это изображение?',
      );
      if (confirmed) {
        try {
          this.isProgress = true;
          await api.delete(
            `/warehouse-stock/${this.selectedProduct.id}/images/${image.id}`,
          );
          this.selectedProduct.images = this.selectedProduct.images.filter(
            (img) => img.id !== image.id,
          );

          this.products.forEach((product, index) => {
            if (product.product_key === this.selectedProduct.product_key) {
              this.products[index].images = this.selectedProduct.images;
            }
          });
          this.isProgress = false;
        } catch (error) {
          console.error('Ошибка при удалении изображения:', error);
          this.isProgress = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.image__delete-icon {
  cursor: pointer;
  transition: all 0.3s;
  background: #ccc;
  border-radius: 5px;
  &:hover {
    transform: scale(1.2);
    color: red;
  }
}

.preloader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.6);

  v-progress-circular {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
