<template>
  <v-container class="register-container">
    <h1>Register</h1>
    <form @submit.prevent="register">
      <div>
        <label for="name">Имя</label>
        <input type="text" v-model="form.name" required />
      </div>
      <div>
        <label for="email">Email:</label>
        <input type="email" v-model="form.email" required />
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="password" v-model="form.password" required />
      </div>
      <button type="submit">Register</button>
    </form>
    <p v-if="error">{{ error }}</p>
    <p v-if="success">{{ success }}</p>
  </v-container>
</template>

<script>
import api from '@/api-service';

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
        name: '',
      },
      error: '',
      success: '',
    };
  },
  methods: {
    async register() {
      this.error = '';
      this.success = '';
      try {
        const response = await api.post('/auth/register', {
          ...this.form,
        });

        if (response) {
          this.success = 'Registration successful!';
          this.$router.push('/auth/login');
        }
      } catch (err) {
        this.error = 'Registration failed. Please try again.';
      }
    },
  },
};
</script>

<style scoped>
.register-container {
  max-width: 300px;
  margin: 0 auto;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}
label {
  display: block;
  margin-bottom: 0.5em;
}
input {
  width: 100%;
  padding: 0.5em;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-radius: 3px;
}
button {
  width: 100%;
  padding: 0.7em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
button:hover {
  background-color: #0056b3;
}
p {
  color: red;
}
</style>
